import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { ModalEditCartaoComponent } from '@app/_components/cliente/cartao-credito/modal-edit-cartao/modal-edit-cartao.component';
import { ModalCadastroEnderecoComponent } from '@app/_components/cliente/endereco-entrega/modal-cadastro-endereco/modal-cadastro-endereco.component';
import { ModalMensagemComponent } from '@app/_components/compartilhados/modal-mensagem/modal-mensagem.component';
import { FormaPagamento } from '@app/_enums/forma-pagamento';
import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';
import { DetalhesCliente } from '@app/_models/cliente/detalhes-cliente';
import { Endereco } from '@app/_models/endereco/endereco';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { PreparoMercadoria } from '@app/_models/mercadoria/preparo-mercadoria';
import { ItemPedido, NovoPedido } from '@app/_models/pedido/novo-pedido';
import { PedidoItem } from '@app/_models/pedido/pedido';
import { Turno } from '@app/_models/turno/turno';
import { ModalAlertService } from '@app/_services/base/alert.service';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { IndoorService } from '@app/_services/indoor/indoor.service';
import { LoginService } from '@app/_services/login/login.service';
import { PedidoService } from '@app/_services/pedido/pedido.service';
import { SacolaService } from '@app/_services/sacola/sacola.service';
import { TurnoService } from '@app/_services/turno/turno.service';
import { CpfCnpjValidator } from '@app/_validators/cpfCnpj-validator';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ScrollHooks } from 'ng-lazyload-image';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModalAdicionarCarrinhoComponent } from '../modal-adicionar-carrinho/modal-adicionar-carrinho.component';
import { ModalPixComponent } from '../modal-pix/modal-pix.component';
import { CriaPedidoMesa } from '@app/_models/mesa/cria-pedido-mesa';
import { MesaService } from '@app/_services/mesa/mesa.service';
import { error } from 'console';
import { IniciarConsumoMesa } from '@app/_models/mesa/inicia-consumo-mesa';
import { CabecalhoReserva } from '@app/_models/mesa/cabecalho-reserva';
import { ItemReserva } from '@app/_models/mesa/item-reserva';
import { ChangeDetectorRef } from '@angular/core';
import { VendedorService } from '@app/_services/vendedor/vendedor.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-resumo-pedido',
  templateUrl: './resumo-pedido.component.html',
  styleUrls: ['./resumo-pedido.component.scss'],
  providers: [{ provide: DecimalPipe, useClass: ScrollHooks }],
})
export class ResumoPedidoComponent implements OnInit {
  @Input() pedidoRealizado: BehaviorSubject<boolean>;
  @Input() atualizaListaCartoes;
  @Input() atualizaListaEnderecos;

  public FormaRetirada = FormaRetiradaPedido;
  telaConclusaoPedido: boolean = false;
  restaurante: DetalhesLoja;
  dataAtual: Date;
  enderecoSelecionado: Endereco;
  mensagemErro: string;
  previsaoTexto: string = "Previsão de entrega";
  carregando = false;
  cpfCnpjNaNota = false;
  cliente: DetalhesCliente;
  form: UntypedFormGroup;
  pedidoMinimoErro: any;
  cpfCnpjMask: string = '00.000.000/0000-00';
  restauranteEstaAberto: boolean;
  proximoTurno: string;
  restaurantePermitePagamentoEntrega: boolean;
  restaurantePermitePagamentoOnline: boolean;
  resumoPedidosPosicaoTop = 0;
  pagamentoViaCartao: boolean;
  aguarde;
  consultaMesa;
  historicoMesa;

  posicaoInicialSacola = -1;
  @ViewChild('resumoPedidos') public resumoPedidos: ElementRef;

  constructor(
    public sacolaService: SacolaService,
    private router: Router,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder,
    private pedidoService: PedidoService,
    private alertaService: ModalAlertService,
    private indoorService: IndoorService,
    private turnoService: TurnoService,
    private modalService: NgbModal,
    public loginService: LoginService,
    private clienteService: ClienteService,
    private _decimalPipe: DecimalPipe,
    private mesaService: MesaService,
    private changeDetectorRef: ChangeDetectorRef,
    private vendedorService: VendedorService,
  ) {

    this.restaurante = loginService.lojaLogada;

    switch (loginService.lojaLogada.ModoPagamento) {
      case "Pagamento na entrega":
        this.sacolaService.formaPagamento = FormaPagamento.Entrega;
        this.restaurantePermitePagamentoEntrega = true;
        if(loginService.lojaLogada.HabilitarPix){
          this.restaurantePermitePagamentoOnline = true;
        }
        break;
      case "Pagamento online e na entrega":
        this.pagamentoViaCartao = true;
        this.restaurantePermitePagamentoOnline = true;
        this.restaurantePermitePagamentoEntrega = true;
        break;
      default:
        this.pagamentoViaCartao = true;
        this.restaurantePermitePagamentoOnline = true;
        this.sacolaService.formaPagamento = FormaPagamento.Loja;
        break;
    }

    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.telaConclusaoPedido = event.url == '/pedido';
        if(this.indoor && event.url == '/pedido') {
          document.body.style.position = 'fixed';
          document.body.style.width = '100%';
        }
      });

    this.dataAtual = new Date(new Date().getTime() + this.restaurante.TempoPreparo * 60000);
    this.sacolaService.pedidoSubject.value.entrega.DataEntrega = this.dataAtual;

    loginService.cliente.subscribe(cliente => {
      this.cliente = cliente;
      this.cpfCnpjMask = this.cliente && this.cliente.CPFCNPJ && this.cliente.CPFCNPJ.length <= 11 ? '000.000.000-00' : '00.000.000/0000-00'
    });

    if (!this.indoor) {
      this.sacolaService.atualizarTaxaEntrega();
      this.clienteService.enderecoSelecionado.subscribe(e => this.enderecoSelecionado = e);
    } else {
      this.carregando = false;
      this.sacolaService.alterarFormaRetirada(FormaRetiradaPedido.Balcao);
    }

    this.validacoesCarrinho();

    this.sacolaService.produtoAdicionado.subscribe(e => {
      if (e) {
        this.pedidoMinimoErro = this.possuiValorMinimoPermitido || this.sacolaVazia ?
          null : this.restaurante.ValorMinimo;
      }
    });
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      CPFCNPJ: [{ value: this.cliente ? this.cliente.CPFCNPJ : '', disabled: !this.cliente || !this.cliente.CPFCNPJ }, [CpfCnpjValidator.validate]],
      cpfCnpjNaNota: [this.cliente && this.cliente.CPFCNPJ]
    });

    this.atualizaMascaraCnpj();

    this.form.get('cpfCnpjNaNota').valueChanges.subscribe(cpfNaNota => {
      const campoCpfCnpj = this.form.get('CPFCNPJ');

      if (!cpfNaNota) {
        campoCpfCnpj.reset();
        campoCpfCnpj.markAsPristine();
        campoCpfCnpj.disable();
      } else {
        campoCpfCnpj.enable();
        campoCpfCnpj.setValue(this.cliente.CPFCNPJ);
      }
    });
  }

  get isMobile() {
    return window.innerWidth < 992;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (!this.isMobile) {

      const posicaoPagina =
        window.scrollY  ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;

      if (this.posicaoInicialSacola == -1) {
        this.posicaoInicialSacola = this.resumoPedidos.nativeElement.children[0].offsetParent.offsetTop;
      }

      var alturaNav = 0;
      if (document?.getElementById("gruposNav")?.clientHeight != null)
        alturaNav = document.getElementById("gruposNav").clientHeight;

      var alturaImagemCapa = 0
      if(document?.getElementById("imagemCapa")?.clientHeight != null)
        alturaImagemCapa = document.getElementById("imagemCapa").clientHeight;

      if(this.router.url != "/pedido"){
        if (this.posicaoInicialSacola + alturaNav + alturaImagemCapa <= posicaoPagina) {
          this.resumoPedidos.nativeElement.classList.add('resumo-pedido-fixed');

          if (this.resumoPedidos?.nativeElement?.firstChild?.clientHeight != null)
            this.resumoPedidosPosicaoTop = document.getElementById("gruposNav").offsetHeight + document.getElementById("pesquisa").offsetHeight;
          else
            this.resumoPedidosPosicaoTop = 0;
        } else {
          this.resumoPedidos.nativeElement.classList.remove('resumo-pedido-fixed');
          this.resumoPedidosPosicaoTop = 0;
        }
      }
    }
  }

  get taxaEntrega(){
    if (this.sacolaService?.taxaEntrega > 0 && !this.retirarNoLocal)
      return this.sacolaService.taxaEntrega;
    else
      return 0;
  }

  getPrecoVariacao(precoVenda, precoComDesconto, quantidade) {
    var preco = precoVenda != precoComDesconto ? precoComDesconto : precoVenda
    return preco * quantidade;
  }

  private atualizaMascaraCnpj() {
    this.form.get('CPFCNPJ').valueChanges.subscribe(e => {
      this.cpfCnpjMask = e && e.length <= 11 ? '000.000.000-000' : '00.000.000/0000-00';
    });
  }

  alterarFormaRetirada(forma: FormaRetiradaPedido) {
    this.sacolaService.alterarFormaRetirada(forma);
    this.sacolaService.atualizarTaxaEntrega();

    if (this.retirarNoLocal) {
      this.previsaoTexto = "Previsão para retirar";
      this.sacolaService.taxaEntrega = 0;
      this.sacolaService.pedidoSubject.value.entrega.Distancia = null
      this.sacolaService.pedidoSubject.value.entrega.DataEntrega = this.dataAtual;
      this.mensagemErro = "";
    } else {
      this.clienteService.enderecoSelecionado.subscribe(e => this.enderecoSelecionado = e);
      this.previsaoTexto = "Previsão para entrega";
      this.sacolaService.taxaEntrega = this.sacolaService.taxaEntrega;
    }
  }

  removerPreparoCarrinho(itemCarrinho, preparo: PreparoMercadoria) {
    this.sacolaService.removerPreparo(itemCarrinho, preparo);
  }

  removerItemCarrinho(codigoItemCarrinho: number) {
    this.sacolaService.removerMercadoria(codigoItemCarrinho);
    this.validacoesCarrinho();
    this.sacolaService.atualizarTaxaEntrega();
  }

  aumentarQuantidadeItemCarrinho(item: PedidoItem){
    var quantidade = item.quantidade + 1;
    this.sacolaService.editarMercadoriaPedido(item.codigoItem, item.mercadoria, quantidade, item.preparos, item.variacao, item.composicoes, item.observacao);
    this.validacoesCarrinho();
    this.sacolaService.atualizarTaxaEntrega();
  }

  diminuirQuantidadeItemCarrinho(item: PedidoItem){
    var quantidade = item.quantidade - 1;
    this.sacolaService.editarMercadoriaPedido(item.codigoItem, item.mercadoria, quantidade, item.preparos, item.variacao, item.composicoes, item.observacao);
    this.validacoesCarrinho();
    this.sacolaService.atualizarTaxaEntrega();
  }

  adicionarNoCarrinho(mercadoria) {
    this.sacolaService.adicionarMercadoriaPedido(mercadoria, 1);
    this.sacolaService.atualizarTaxaEntrega();
  }

  calculaDesconto(precoAtual, porcentagemDesconto) {
    return porcentagemDesconto ?
      precoAtual - precoAtual * (porcentagemDesconto / 100) :
      precoAtual;
  }

  pedirAgora() {
    if (this.mostraMensagemErro()) {
      return;
    } else {
      if (this.pedidoRealizado) {
        this.pedidoRealizado.next(true);
      }

      this.turnoService.listaTurno(this.restaurante.CodTurno).subscribe((e) => {
        this.verificaEstaAberto(e).subscribe(aberto => {
          this.restauranteEstaAberto = aberto;
          if (aberto) {
            this.validaValorMinimo();
          } else {
            this.mostrarMensagemRestauranteFechado();
          }
        });
      });
    }
  }

  private mostraMensagemErro() {

    if (!this.loginService.consumidorLogadoToken) {
      this.router.navigate(['/login'], { queryParams: { returnUrl: 'pedido' } });
    }

    if (this.sacolaVazia) {
      this.toastr.error("A sacola está vazia.", "Não foi possível concluir o pedido", {
        positionClass: 'toast-bottom-left',
      });
      return true;
    }

    if (this.pedidoMinimoErro && !this.indoor) {
      this.toastr.error("O pedido mínimo é R$ " + this._decimalPipe.transform(this.restaurante.ValorMinimo, '1.2-2'), "Erro", {
        positionClass: 'toast-bottom-left',
      });
      return true;
    }

    if(!this.restaurante.HabilitarEntrega && !this.restaurante.HabilitarPraRetirar && this.restaurante.HabilitarNaMesa && !this.indoor){
      this.toastr.error("Mesa não vinculada.", "Erro", {
        positionClass: 'toast-bottom-left',
      });
      return true;
    }

    if (this.telaConclusaoPedido) {

      if (this.restaurante.ModoPagamento == "Pagamento na entrega" && this.indoor && !this.restaurante.HabilitarPagamentoRetira) {
        this.toastr.error("O estabelecimento não aceita finalizar pedidos na mesa.", "Não foi possível concluir o pedido", {
          positionClass: 'toast-bottom-left',
        });
        return true;
      }

      if(!this.indoor){
        if (!this.retirarNoLocal && this.enderecoSelecionado && !this.clienteService.permiteEntregaNoEnderecoSelecionado) {
          this.toastr.error("O pedido não pode ser entregue no endereço selecionado.", "Não foi possível concluir o pedido", {
            positionClass: 'toast-bottom-left',
          });
          return true;
        }
  
        if(!this.retirarNoLocal && this.clienteService.enderecos?.length == 0 && !this.enderecoSelecionado){
          this.toastr.error("Você deve cadastrar um endereço de entrega.", "Não foi possível concluir o pedido", {
            positionClass: 'toast-bottom-left',
          });
          return true;
        };
  
        if(!this.sacolaService.tipoPagamentoSelecionado){
          this.toastr.error("Você deve selecionar uma forma de pagamento", "Não foi possível concluir o pedido", {
            positionClass: 'toast-bottom-left',
          });
          return true;
        }
  
        if (this.sacolaService.formaPagamento == FormaPagamento.Loja && !this.sacolaService.cartaoSelecionado && this.sacolaService.tipoPagamentoSelecionado.Codigo != 26) {
          document.body.style.position = 'fixed';
          
          const modalRef = this.modalService.open(ModalEditCartaoComponent, { size: 'lg', scrollable: true });
  
          this.toastr.error("Você deve cadastrar um cartão.", "Não foi possível concluir o pedido", {
            positionClass: 'toast-bottom-left',
          });
  
          modalRef.result.then((cartao) => {
            if (cartao) {
              this.atualizaListaCartoes.next(cartao);
            }
            document.body.style.position = '';
          });
  
          return true;
        }
  
        if (this.sacolaService.formaPagamento == FormaPagamento.Entrega
          && this.sacolaService.tipoPagamentoSelecionado == undefined) {
          this.toastr.error("Você deve selecionar uma forma de pagamento", "Não foi possível concluir o pedido", {
            positionClass: 'toast-bottom-left',
          });
          return true;
        }
      }
    }

    return false;
  }

  private mostrarMensagemRestauranteFechado() {
    let mensagemErro = 'Mas você pode olhar o cardápio à vontade e voltar quando a loja estiver aberta.';
    let titulo = "Estamos fechados";

    if (this.proximoTurno) {
      titulo = "Esta loja abre às " + this.proximoTurno;
    }

    document.body.style.position = 'fixed';

    const modalRef = this.modalService.open(ModalMensagemComponent);
    modalRef.componentInstance.titulo = titulo;
    modalRef.componentInstance.mensagem = mensagemErro;

    modalRef.result.then((e) => {
      document.body.style.position = '';
    }).catch((e) => {
      document.body.style.position = '';
    });
  }

  private validaValorMinimo() {
    if (!this.possuiValorMinimoPermitido && !this.indoor) {
      this.pedidoMinimoErro = this.restaurante.ValorMinimo;
    } else {
      this.confirmaConclusao();
    }
  }

  private confirmaConclusao() {
    if (this.telaConclusaoPedido) {
      this.finalizaPedido();
    } else {
      this.vaiParaTelaPedido();
    }
  }

  private finalizaPedido() {
    this.alertaService.mostraAlerta('Você quer finalizar o pedido?', 'warning', 'Atenção', () => {
      if(this.indoor){
        this.realizaPedidoMesa();
      } else {
        this.realizaPedido();
      }
    }, () => { }, true, 'Cancelar', 'Sim');
  }

  private verificaEstaAberto(e: any) {
    const turnos: Turno[] = e.value;
    const diaDaSemanaHoje = new Date().getDay() + 1;
    const horaFormatada = new Date().getHours() + ":" + (new Date().getMinutes()).toString().padStart(2, '0');

    const aberto = turnos.some(t => {
      const horarioEntrada = t.Entrada.substring(11, t.Entrada.length);
      const horarioSaida = t.Saida.substring(11, t.Saida.length);

      return t.NumeroDiaSemana == diaDaSemanaHoje &&
        Date.parse(`01/01/2011 ${horaFormatada}:00`) >= Date.parse(`01/01/2011 ${horarioEntrada}:00`) &&
        Date.parse(`01/01/2011 ${horarioSaida}:00`) >= Date.parse(`01/01/2011 ${horaFormatada}:00`);
    });

    let horarioProximoTurno;

    if (!aberto) {
      const proximoTurno = turnos
        .filter(e => e.NumeroDiaSemana == diaDaSemanaHoje
          && Date.parse(`01/01/2011 ${e.Entrada.substring(11, e.Entrada.length)}:00`)
          >= Date.parse(`01/01/2011 ${horaFormatada}:00`)
        )[0];

      if (proximoTurno) {
        horarioProximoTurno = proximoTurno.Entrada.substring(11, proximoTurno.Entrada.length);
        this.proximoTurno = horarioProximoTurno;
      }
    }

    return of(aberto);
  }

  private vaiParaTelaPedido() {
    this.router.navigate(['/pedido']);
  }

  formataData(data: Date) {
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear().toString();
    const hora = data.getHours().toString().padStart(2, '0');
    const minuto = data.getMinutes().toString().padStart(2, '0');
    const segundo = data.getSeconds().toString().padStart(2, '0');

    return `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}`;
  }

  private realizaPedido() {

    this.chamaLoader();
    
    var pedido = new NovoPedido();
    pedido.Agendado = false;
    let numeroItem = 1;

    if (this.restaurante.CodAppClearSale) {
      pedido.CodAntiFraude = (<HTMLInputElement>document.getElementById("codAntiFraude")).value;
    }

    pedido.EstadoEntrega = this.restaurante.EstadoEntrega;
    pedido.CodEnderecoConsumidor = this.clienteService.enderecoSelecionadoSubject?.Codigo > 0 ? this.clienteService.enderecoSelecionadoSubject.Codigo : 0;
    pedido.TaxaEntrega = Number.parseFloat(this.taxaEntrega.toFixed(2));
    pedido.Codigo = this.sacolaService.pedidoSubject.value.Codigo;
    pedido.DataPrevisao = this.formataData(this.sacolaService.pedidoSubject.value.entrega.DataEntrega);
    pedido.DataCriacao = this.formataData(new Date());
    pedido.Desconto = 0;
    pedido.DetalheEntregaRetira = "";
    pedido.ModoEntregaRetira = this.indoor || this.retirarNoLocal ? FormaRetiradaPedido.Balcao : FormaRetiradaPedido.Entrega;
    pedido.Observacao = "";
    pedido.Retira = this.retirarNoLocal;
    pedido.SubTotal = Number.parseFloat(this.sacolaService.valorTotal.toFixed(2));
    pedido.TempoPreparo = this.restaurante.TempoPreparo;
    pedido.Total = Number.parseFloat(this.valorTotalCarrinho.toFixed(2));

    pedido.Transacoes = [
      {
        CodCartao: this.sacolaService.cartaoSelecionado?.Codigo && this.sacolaService.cartaoSelecionado?.Codigo != "{00000000-0000-0000-0000-000000000000}" ? this.sacolaService.cartaoSelecionado.Codigo : "",
        CodTipoPagamento: this.sacolaService.tipoPagamentoSelecionado.Codigo,
        NumeroDoc: "",
        Troco: Number.parseFloat(this.sacolaService.valorTroco.toFixed(2)),
        Valor: Number.parseFloat(this.valorTotalCarrinho.toFixed(2)),
        Online: this.sacolaService.tipoPagamentoSelecionado.Online
      }
    ];

    pedido.Itens = [];
    this.sacolaService.listarMercadorias().forEach(e => {
      let numeroItemPrincipal = numeroItem;
      let desconto = (e.mercadoria.DescontoVenda * e.quantidade * e.mercadoria.PrecoVenda) / 100;
      let total = (e.mercadoria.PrecoVenda * e.quantidade) - desconto;

      var p = {
        CodBasico: e.mercadoria.Codigo,
        CodBarras: e.mercadoria.CodBarras && e.mercadoria.CodBarras != "" ?
          e.mercadoria.CodBarras :
          e.mercadoria.Codigo,
        Contem: e.composicoes && e.composicoes.length > 0 ? -1 : 0,
        ContemPreparo: e.preparos && e.preparos.length > 0 ? -1 : 0,
        Desconto: Number.parseFloat(e.mercadoria.DescontoVenda.toFixed(2)),
        DescontoValor: Number.parseFloat(desconto.toFixed(2)),
        NumeroItem: numeroItem,
        Preparo: e.observacao ? "".concat(e.observacao) + "  " : "",
        Quantidade: e.quantidade,
        Valor: Number.parseFloat(e.mercadoria.PrecoVenda.toFixed(2)),
        Total: Number.parseFloat(total.toFixed(2)),
        Observacao: e.observacao
      } as ItemPedido;

      numeroItem++;

      let somaValorContidas = 0;

      if (e.composicoes && e.composicoes.length > 0) {
        e.composicoes.forEach((composicaoItem, index) => {

          var c = {
            CodBasico: composicaoItem.CodBasicoContida,
            CodBarras: composicaoItem.CodBarrasContida,
            Contem: numeroItemPrincipal,
            ContemPreparo: 0,
            Desconto: composicaoItem.DescontoPorcentagem,
            DescontoValor: composicaoItem.DescontoValor,
            NumeroItem: numeroItem,
            Preparo: "",
            Quantidade: e.quantidade * composicaoItem.Quantidade,
            Valor: composicaoItem.PrecoVenda,
            Total: composicaoItem.Total,
          } as ItemPedido;

          somaValorContidas += c.Total;

          if (composicaoItem.IsVariacao && composicaoItem.Variacao) {
            c.CodBarras = composicaoItem.Variacao.CodMercVar;
            c.CodBasico = composicaoItem.Variacao.CodBasico;
          }

          if(e.preparos && e.preparos.length > 0){
            e.preparos.forEach(preparo => {
              if(preparo.CodBasicoContida == composicaoItem.CodBasicoContida){
                c.Preparo += preparo.Nome + "  ";
                e.preparos = e.preparos.filter(
                  preparoNaLista => (preparoNaLista.CodBasicoContida != composicaoItem.CodBasicoContida || preparoNaLista.Nome != preparo.Nome) 
                )
              }
            })
          }
          
          numeroItem++;
          pedido.Itens.push(c);
        });

        let valorSobrando = somaValorContidas - p.Total;

        if (valorSobrando > 0) {
          let valor = pedido.Itens[0].Valor;
          pedido.Itens[0].Total = Number.parseFloat(((valor * pedido.Itens[0].Quantidade) - valorSobrando).toFixed(2));
          let porcentagemDesconto = (valorSobrando * 100) / valor;

          pedido.Itens[0].DescontoValor = Number.parseFloat(valorSobrando.toFixed(2));
          pedido.Itens[0].Desconto = Number.parseFloat(porcentagemDesconto.toFixed(2));
        }
      }

      if (e.preparos && e.preparos.length > 0) {
        e.preparos.forEach(preparo => {

          if (preparo.CodBasico && preparo.CodBasico != "") {
            var prep = {
              CodBasico: preparo.CodBasico,
              CodBarras: preparo.CodBarras != "" ? preparo.CodBarras : preparo.CodBasico,
              Contem: 0,
              ContemPreparo: numeroItemPrincipal,
              Desconto: 0,
              DescontoValor: 0,
              NumeroItem: numeroItem,
              Preparo: preparo.PermitePersonalizacao ? preparo.ValorPersonalizacao : "",
              Quantidade: e.quantidade * preparo.Quantidade,
              Valor: Number.parseFloat(preparo.PrecoVenda.toFixed(2)),
              Total: Number.parseFloat((preparo.PrecoVenda * e.quantidade * preparo.Quantidade).toFixed(2)),
            } as ItemPedido;
            pedido.Itens.push(prep);
            numeroItem++;
          } 

          p.Preparo = p.Preparo == "" ?
          p.Preparo.concat(`${preparo.Nome} ${(preparo.PermitePersonalizacao ? '- ' + preparo.ValorPersonalizacao + ' ' : ' ')}`) :
          p.Preparo.concat(`${preparo.Nome} ${(preparo.PermitePersonalizacao ? '- ' + preparo.ValorPersonalizacao + ' ' : ' ')}`);
        
        });
      }

      if (e.variacao) {
        const valorVariacao = (e.variacao.PrecoVendaComDesconto != e.variacao.precoVenda ? e.variacao.PrecoVendaComDesconto : e.variacao.PrecoVenda);
        const descontoValor = (e.variacao.PrecoVenda - e.variacao.PrecoVendaComDesconto) * e.quantidade;
        p.Valor = e.variacao.PrecoVenda;
        p.Total = Number.parseFloat((valorVariacao * e.quantidade).toFixed(2));
        p.CodBarras = e.variacao.CodMercVar;
        p.Desconto = Number.parseFloat(((100 * descontoValor) / (e.variacao.PrecoVenda * e.quantidade)).toFixed(2));
        p.DescontoValor = descontoValor;
      }

      pedido.Itens.push(p);
    });

    pedido.Itens = pedido.Itens.sort((a, b) => {
      return a.NumeroItem > b.NumeroItem ? 0 : -1;
    });

    if(this.vendedorInformado){
      pedido.CodVendedor = Number(this.vendedor);
    }

    if(pedido.Transacoes[0].CodTipoPagamento == 26 && pedido.Transacoes[0].Online){

      this.pedidoService.criarPagamento(pedido).subscribe(e => {
        document.body.style.position = 'fixed';
        const modalRef = this.modalService.open(ModalPixComponent, {
          keyboard: false,
          scrollable: true,
        });
        modalRef.componentInstance.pix = e;
        modalRef.componentInstance.pedido = pedido;

        modalRef.result.then((e) => {
          document.body.style.position = '';
        }).catch((e) => {
          document.body.style.position = '';
        });
      }, (err) => {
        const modalRef = this.modalService.open(ModalMensagemComponent, {
          backdrop: 'static',
          keyboard: false,
          scrollable: true
        });
        modalRef.componentInstance.titulo = 'Ocorreu um erro';
        modalRef.componentInstance.centralizarTexto = true;
        modalRef.componentInstance.mensagem = "Não foi possível concluir o pedido";
        modalRef.componentInstance.detalhes = err;
        modalRef.componentInstance.imagem = "/assets/images/finalizar-erro.svg";
        
        modalRef.result.then((e) => {
          document.body.style.position = '';
        })
        .catch((e) => {
          document.body.style.position = '';
        });
      });

      setTimeout(() => {
        this.carregando = false;
      }, 4000);

    } else {
      this.pedidoService.realizarPedido(pedido).subscribe(e => {
        setTimeout(() => {
          this.aguarde.close();
          document.body.style.position = '';
          this.router.navigate(['acompanhamento', e.value]);
        }, 2000);
        
        this.sacolaService.pedidoRealizado();
        this.carregando = false;
      }, (err) => {
        this.aguarde.close();
        this.carregando = false;
        const modalRef = this.modalService.open(ModalMensagemComponent, {
          backdrop: 'static',
          keyboard: false,
          scrollable: true
        });
        modalRef.componentInstance.titulo = 'Ocorreu um erro';
        modalRef.componentInstance.centralizarTexto = true;
        modalRef.componentInstance.mensagem = "Não foi possível concluir o pedido";
        modalRef.componentInstance.detalhes = err;
        modalRef.componentInstance.imagem = "/assets/images/finalizar-erro.svg";
        
        modalRef.result.then((e) => {
          document.body.style.position = '';
        })
        .catch((e) => {
          document.body.style.position = '';
        });
      });
    }

  }

  private realizaPedidoMesa(){
    this.consultaEstadoMesa.subscribe((e) => {
      this.consultaMesa = e;
      if (e.Cartao.Estado == "Disponível"){
        this.iniciaConsumo();
      } else if (e.Cartao.Estado == "Em uso"){
        this.enviaItensPedidoMesa();
      } else {
        this.toastr.error("A mesa não está disponível para realizar pedidos.", "Não foi possível concluir o pedido", {
          positionClass: 'toast-bottom-left',
        });
      }
    });
  }

  private enviaItensPedidoMesa(){
    this.chamaLoader();
    this.selecionaEBloqueiaMesa().then(historicoMesa => {

      var pedidoMesa = new CriaPedidoMesa;
      let numeroItem = 1;
  
      pedidoMesa.CabReserva = {
        CodLoja: this.restaurante.CodLoja,
        Codigo: this.historicoMesa.Reservas[0].Codigo,
        CodCartao: this.usaComanda ? Number(this.comanda) : Number(this.numeroMesa),
        CodEstacao: this.historicoMesa.Reservas[0].CodEstacao,
        CodUsuario: 1,
        Estacao: this.historicoMesa.Reservas[0].Estacao,
        CodCaixa: this.historicoMesa.Reservas[0].CodCaixa,
        CodCliente: this.historicoMesa.CodCliente,
        CodLocal: this.usaComanda ? Number(this.numeroMesa) : 0,
        Data: this.dataAtual,
        Desconto: 0,
        Estado: this.historicoMesa.Reservas[0].Estado,
        ModoOperacao: this.historicoMesa.Reservas[0].ModoOperacao,
        Pessoas: this.historicoMesa.Reservas[0].Pessoas,
        Subtotal: Number.parseFloat(this.sacolaService.valorTotal.toFixed(2)) + this.historicoMesa.Reservas[0].SubTotal,
        Total: Number.parseFloat(this.valorTotalCarrinho.toFixed(2)) + this.historicoMesa.Reservas[0].Total,
        GUID: "",
      };
  
      pedidoMesa.ItemReserva = [];

      this.historicoMesa.Itens.forEach(item => {

        var p = {
          CodBasico: item.Codigo,
          CodBarras: item.CodBarras && item.CodBarras != "" ? item.CodBarras : item.Codigo,
          Contem: item.composicoes && item.composicoes.length > 0 ? -1 : 0,
          ContemPreparo: item.preparos && item.preparos.length > 0 ? -1 : 0,
          Desconto: Number.parseFloat(item.Desconto.toFixed(2)),
          DescontoValor: Number.parseFloat(item.DescontoValor.toFixed(2)),
          NumeroItem: item.NumeroItem,
          Preparo: item.Preparo,
          Quantidade: item.Quantidade,
          Valor: Number.parseFloat(item.Valor.toFixed(2)),
          Total: Number.parseFloat(item.Total.toFixed(2)),      
          CodLoja: item.CodLoja,
          CodCartao: item.CodCartao,
          CodReserva: item.CodReserva,
          CodEstacao: item.CodEstacao,
          TipoItemVenda: item.TipoItemVenda,
          Data: item.Data,
          ItemNovo: false,
        } as ItemReserva;

        numeroItem++;

        pedidoMesa.ItemReserva.push(p);
        
      });

      this.sacolaService.listarMercadorias().forEach(e => {
        let numeroItemPrincipal = numeroItem;
        let desconto = (e.mercadoria.DescontoVenda * e.quantidade * e.mercadoria.PrecoVenda) / 100;
        let total = (e.mercadoria.PrecoVenda * e.quantidade) - desconto;
  
        var p = {
          CodBasico: e.mercadoria.Codigo,
          CodBarras: e.mercadoria.CodBarras && e.mercadoria.CodBarras != "" ? e.mercadoria.CodBarras : e.mercadoria.Codigo,
          Contem: e.composicoes && e.composicoes.length > 0 ? -1 : 0,
          ContemPreparo: e.preparos && e.preparos.length > 0 ? -1 : 0,
          Desconto: Number.parseFloat(e.mercadoria.DescontoVenda.toFixed(2)),
          DescontoValor: Number.parseFloat(desconto.toFixed(2)),
          NumeroItem: numeroItem,
          Preparo: e.observacao ? "".concat(e.observacao) + "  " : "",
          Quantidade: e.quantidade,
          Valor: Number.parseFloat(e.mercadoria.PrecoVenda.toFixed(2)),
          Total: Number.parseFloat(total.toFixed(2)),      
          CodLoja: this.restaurante.CodLoja,
          CodCartao: this.usaComanda ? Number(this.comanda) : Number(this.numeroMesa),
          CodReserva: this.consultaMesa.CodReserva,
          CodEstacao: this.historicoMesa.Reservas[0].CodEstacao,
          TipoItemVenda: e.mercadoria.TipoItemVenda,
          Data: this.dataAtual,
          ItemNovo: true,
        } as ItemReserva;
  
        numeroItem++;
  
        let somaValorContidas = 0;
  
        if (e.composicoes && e.composicoes.length > 0) {
          e.composicoes.forEach((composicaoItem, index) => {
  
            var c = {
              CodBasico: composicaoItem.CodBasicoContida,
              CodBarras: composicaoItem.CodBarrasContida,
              Contem: numeroItemPrincipal,
              ContemPreparo: 0,
              Desconto: composicaoItem.DescontoPorcentagem,
              Data: this.dataAtual,
              DescontoValor: composicaoItem.DescontoValor,
              NumeroItem: numeroItem,
              Preparo: "",
              Quantidade: e.quantidade * composicaoItem.Quantidade,
              Valor: composicaoItem.PrecoVenda,
              Total: composicaoItem.Total,
            } as ItemReserva;
  
            somaValorContidas += c.Total;
  
            if (composicaoItem.IsVariacao && composicaoItem.Variacao) {
              c.CodBarras = composicaoItem.Variacao.CodMercVar;
              c.CodBasico = composicaoItem.Variacao.CodBasico;
            }
  
            if(e.preparos && e.preparos.length > 0){
              e.preparos.forEach(preparo => {
                if(preparo.CodBasicoContida == composicaoItem.CodBasicoContida){
                  c.Preparo += preparo.Nome + "  ";
                  e.preparos = e.preparos.filter(
                    preparoNaLista => (preparoNaLista.CodBasicoContida != composicaoItem.CodBasicoContida || preparoNaLista.Nome != preparo.Nome) 
                  )
                }
              })
            }
            
            numeroItem++;
            pedidoMesa.ItemReserva.push(c);
          });
  
          let valorSobrando = somaValorContidas - p.Total;
  
          if (valorSobrando > 0) {
            let valor = pedidoMesa.ItemReserva[0].Valor;
            pedidoMesa.ItemReserva[0].Total = Number.parseFloat(((valor * pedidoMesa.ItemReserva[0].Quantidade) - valorSobrando).toFixed(2));
            let porcentagemDesconto = (valorSobrando * 100) / valor;
  
            pedidoMesa.ItemReserva[0].DescontoValor = Number.parseFloat(valorSobrando.toFixed(2));
            pedidoMesa.ItemReserva[0].Desconto = Number.parseFloat(porcentagemDesconto.toFixed(2));
          }
        }
  
        if (e.preparos && e.preparos.length > 0) {
          e.preparos.forEach(preparo => {
  
            if (preparo.CodBasico && preparo.CodBasico != "") {
              var prep = {
                CodBasico: preparo.CodBasico,
                CodBarras: preparo.CodBarras != "" ? preparo.CodBarras : preparo.CodBasico,
                Contem: 0,
                ContemPreparo: numeroItemPrincipal,
                Desconto: 0,
                Data: this.dataAtual,
                DescontoValor: 0,
                NumeroItem: numeroItem,
                Preparo: preparo.PermitePersonalizacao ? preparo.ValorPersonalizacao : "",
                Quantidade: e.quantidade * preparo.Quantidade,
                Valor: Number.parseFloat(preparo.PrecoVenda.toFixed(2)),
                Total: Number.parseFloat((preparo.PrecoVenda * e.quantidade * preparo.Quantidade).toFixed(2)),
              } as ItemReserva;
              pedidoMesa.ItemReserva.push(prep);
              numeroItem++;
            } 
  
            p.Preparo = p.Preparo == "" ?
            p.Preparo.concat(`${preparo.Nome} ${(preparo.PermitePersonalizacao ? '- ' + preparo.ValorPersonalizacao + ' ' : ' ')}`) :
            p.Preparo.concat(`${preparo.Nome} ${(preparo.PermitePersonalizacao ? '- ' + preparo.ValorPersonalizacao + ' ' : ' ')}`);
          
          });
        }
  
        if (e.variacao) {
          const valorVariacao = (e.variacao.PrecoVendaComDesconto != e.variacao.precoVenda ? e.variacao.PrecoVendaComDesconto : e.variacao.PrecoVenda);
          const descontoValor = (e.variacao.PrecoVenda - e.variacao.PrecoVendaComDesconto) * e.quantidade;
          p.Valor = e.variacao.PrecoVenda;
          p.Total = Number.parseFloat((valorVariacao * e.quantidade).toFixed(2));
          p.CodBarras = e.variacao.CodMercVar;
          p.Desconto = Number.parseFloat(((100 * descontoValor) / (e.variacao.PrecoVenda * e.quantidade)).toFixed(2));
          p.DescontoValor = descontoValor;
        }
  
        pedidoMesa.ItemReserva.push(p);
      });
  
      this.mesaService.criarPedidoMesa(pedidoMesa).subscribe(e => {
        setTimeout(() => {
          this.aguarde.close();
          document.body.style.position = '';
          this.desbloqueiaMesa();
          this.router.navigate(['indoor/Mesa' + this.numeroMesa +'/comanda']);
        }, 2000);
        
        this.sacolaService.pedidoRealizado();
        this.carregando = false;
      }, (err) => {
        this.aguarde.close();
        this.carregando = false;
        const modalRef = this.modalService.open(ModalMensagemComponent, {
          backdrop: 'static',
          keyboard: false,
          scrollable: true
        });
        modalRef.componentInstance.titulo = 'Ocorreu um erro';
        modalRef.componentInstance.centralizarTexto = true;
        modalRef.componentInstance.mensagem = "Não foi possível concluir o pedido";
        modalRef.componentInstance.detalhes = err;
        modalRef.componentInstance.imagem = "/assets/images/finalizar-erro.svg";
        
        modalRef.result.then((e) => {
          document.body.style.position = '';
        })
        .catch((e) => {
          document.body.style.position = '';
        });
      });

    });
  }

  desbloqueiaMesa(){
    var mesaCartao;
    
    if(this.indoorService.usaComanda){
      mesaCartao = this.indoorService.comanda;
    } else {
      mesaCartao = this.indoorService.numeroMesa;
    }

    return this.mesaService.desbloquearMesa(mesaCartao);
  }

  selecionaEBloqueiaMesa(){
    return new Promise((resolve, reject) => {

      var mesaCartao;
    
      if(this.indoorService.usaComanda){
        mesaCartao = this.indoorService.comanda;
      } else {
        mesaCartao = this.indoorService.numeroMesa;
      }
      
      this.mesaService.bloquearMesa(mesaCartao).subscribe((e) => {
        this.historicoMesa = e;
        resolve(e);
      }, (error) => {
        reject('Nenhum resultado encontrado');
      });

    });
  }

  get consultaEstadoMesa(){
    var mesaCartao;
    
    if(this.indoorService.usaComanda){
      mesaCartao = this.indoorService.comanda;
    } else {
      mesaCartao = this.indoorService.numeroMesa;
    }

    return this.mesaService.selecionarMesa(mesaCartao);
  }

  private iniciaConsumo(){
    var mesaCliente = new IniciarConsumoMesa();
    
    if(this.usaComanda){
      mesaCliente.CodMesaCartao = Number(this.comanda);
      mesaCliente.CodMesaCartao = Number(this.numeroMesa);
    } else {
      mesaCliente.CodMesaCartao = Number(this.numeroMesa);
    }

    mesaCliente.ClienteNome = this.cliente.Nome;
    mesaCliente.ClienteFone1 = this.cliente.Celular;
    mesaCliente.ClienteCPFCNPJ = this.cliente.CPFCNPJ;
    
    this.mesaService.iniciarConsumoMesa(mesaCliente).subscribe((e) => {
      this.enviaItensPedidoMesa();
    });
  }

  private chamaLoader(){
    this.carregando = true;

    if( this.sacolaService.tipoPagamentoSelecionado?.Codigo != 26 || !this.sacolaService.tipoPagamentoSelecionado.Online || this.indoor){
      document.body.style.position = 'fixed';
      this.aguarde = this.modalService.open(ModalMensagemComponent, {
        backdrop: 'static',
        keyboard: false
      });
      this.aguarde.componentInstance.titulo = 'Finalizando pedido';
      this.aguarde.componentInstance.centralizarTexto = true;
      this.aguarde.componentInstance.mensagem = 'Aguarde, estamos finalizando seu pedido!';
      this.aguarde.componentInstance.mostrarBotaoConfirmar = false;
      this.aguarde.componentInstance.imagem = "/assets/images/preparando-pedido.svg";
    }
  }

  private validacoesCarrinho() {
    if (!this.possuiValorMinimoPermitido && !this.sacolaVazia) {
      this.pedidoMinimoErro = this.restaurante.ValorMinimo;
    } else {
      this.pedidoMinimoErro = null;
    }

    if (this.router.url.includes("pedido") && this.sacolaService.listarMercadorias() != null && this.sacolaVazia) {
      this.router.navigate(['/']);
      this.toastr.error('Seu carrinho está vazio', 'Aviso', {
        positionClass: 'toast-bottom-left',
      });
    }
  }

  editarItem(item: PedidoItem) {
    this.turnoService.listaTurno(this.restaurante.CodTurno).subscribe((e) => {
      this.verificaEstaAberto(e).subscribe(aberto => {
        this.restauranteEstaAberto = aberto;

        document.body.style.position = 'fixed';
        
        const tamanhoModal = item.mercadoria.Imagens && item.mercadoria.Imagens.length > 0 ? 'xl' : 'lg';
        const modalRef = this.modalService.open(ModalAdicionarCarrinhoComponent, { size: tamanhoModal });
        modalRef.componentInstance.mercadoria = item.mercadoria;
        modalRef.componentInstance.variacaoSelecionada = item.variacao;
        modalRef.componentInstance.preparosSelecionados = item.preparos;
        modalRef.componentInstance.quantidade = item.quantidade;
        modalRef.componentInstance.composicoesSelecionadas = item.composicoes;
        modalRef.componentInstance.variacaoComposicoesSelecionadas = item.composicoes
          .filter(e => e.TipoItemVenda == "Com variação")
          .map(e => e.Variacao);
        modalRef.componentInstance.observacao = item.observacao;
        modalRef.componentInstance.restaurante = this.restaurante;
        modalRef.componentInstance.restauranteAberto = this.restauranteEstaAberto;
        modalRef.componentInstance.proximoTurno = this.proximoTurno;
        modalRef.componentInstance.codigoMercadoriaSacola = item.codigoItem;

        modalRef.result.then((e) => {
          document.body.style.position = '';
        })
        .catch((e) => {
          document.body.style.position = '';
        });
      });
    });
  }

  abrirModalSelecaoOpcaoEntrega() {
    document.body.style.position = 'fixed';

    let opcoesModal: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
      scrollable: true
    };

    const modalRef = this.modalService.open(ModalCadastroEnderecoComponent, opcoesModal);
    modalRef.result.then((e) => {
      document.body.style.position = '';
    })
    .catch((e) => {
      document.body.style.position = '';
    });
  }

  preparosComposicao(codBasicoContida: string, preparos: any[], codComposicao){
    var preparosComposicao = preparos.filter(preparo => preparo.CodBasicoContida == codBasicoContida && preparo.CodComposicao == codComposicao );
    return preparosComposicao;
  }

  imagemURL(item: any) {
    if (item.mercadoria.Imagens && item.mercadoria.Imagens.length > 0) {
      const imagem = item.mercadoria.Imagens.find((i) => i.Tipo === 'Principal');
      if (imagem && imagem.ImagemBase64) {
        return `data:image/png;base64,${imagem.ImagemBase64}`;
      } else if (imagem && imagem.Imagem) {
        return imagem.Imagem;
      }
    }

    return false;
  }

  esvaziarCarrinho(){
    Swal.fire({
      title: 'Esvaziar Carrinho',
      text: 'Você tem certeza que deseja remover todos os itens do carrinho?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.sacolaService.esvaziarCarrinho();
        this.changeDetectorRef.detectChanges();
      }
    })
  }

  ngOnDestroy() {
    document.body.style.position = '';
  }

  get sacolaVazia() {
    return this.sacolaService.listarMercadorias() == null || this.sacolaService.listarMercadorias().length <= 0;
  };

  get podeRealizarPedido() {
    if (!this.telaConclusaoPedido) {
      if (this.clienteService.selecionarFormaRetirada != FormaRetiradaPedido.Entrega)
        return true;
      else
        return this.clienteService.permiteEntregaNoEnderecoSelecionado &&
          (this.clienteService.enderecoSelecionadoSubject != null
            && this.clienteService.selecionarFormaRetirada == FormaRetiradaPedido.Entrega)
    } else {
      return true.valueOf;
    }
  }

  get valorTotalCarrinho() {
    return this.sacolaService.valorTotal +  this.taxaEntrega;
  }

  get possuiValorMinimoPermitido() {
    return this.sacolaService.valorTotal >= this.restaurante.ValorMinimo;
  }

  get itensCarrinho() {
    return this.sacolaService.listarMercadorias();
  }

  get indoor() { 
    return this.indoorService.indoor; 
  }

  get mostrarAmanha() {
    return this.sacolaService.pedidoSubject.value.entrega.MostrarAmanha;
  }

  get dataEntrega() {
    return this.sacolaService.pedidoSubject.value.entrega.DataEntrega;
  }

  get distancia() {
    return this.sacolaService.pedidoSubject.value.entrega.Distancia;
  }

  get retirarNoLocal() {
    return this.clienteService.selecionarFormaRetirada == FormaRetiradaPedido.Balcao;
  }

  get numeroMesa() {
    if (this.indoorService.numeroMesa) {
      return this.indoorService.numeroMesa;
    }
    return null;
  }

  get usaComanda() {
    return this.indoorService.usaComanda;
  }

  get comanda() {
    return this.indoorService.comanda;
  }

  get vendedorInformado(){
    return this.vendedorService.vendedorInformado;
  }

  get vendedor(){
    return this.vendedorService.numeroVendedor;
  }
}
