<div class="imagem"></div>
<div id="register_bg">
  <div id="register">
    <aside>

      <div>
        <div style="text-align: center; margin-bottom: 10px;">
          <button title="Voltar" type="button" class="mfp-back" routerLink="/"></button>
          <h4>Crie sua conta</h4>
        </div>
      </div>

      <figure>
        <a routerLink="/"><img [src]="logo" width="auto" height="54" alt=""></a>
      </figure>

      <div style="text-align: center;">
        <img src="https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/cadastro.svg" alt="Pessoa parada segurando um celular, apoiada em um celular grande. Na tela, um ícone de foto com a palavra 'welcome', campos de login e senha, e abaixo um botão de login." width="250px">
      </div>


      <form autocomplete="off" [formGroup]="form">
        <div class="form-group">
          <input class="form-control" type="text" placeholder="Nome" formControlName="Nome" autofocus>
          <mat-icon class="icon material-icons-outlined">edit_note</mat-icon>
          <small *ngIf="nome.errors && nome.errors.required && (nome.dirty || nome.touched)">O nome é
            obrigatório</small>
          <small *ngIf="nome.errors && nome.errors.nomeInvalido && (nome.dirty || nome.touched)">Informe o nome e sobrenome</small>
        </div>
        <div class="form-group">

          <input class="form-control" placeholder="CPF/CNPJ" formControlName="CPFCNPJ" [mask]="cpfCnpjMask"
            [validation]="false">
            <mat-icon class="icon material-icons-outlined">edit_note</mat-icon>
          <small *ngIf="cpfCnpj.errors && (cpfCnpj.dirty || cpfCnpj.touched)">O
            {{ cpfCnpj.value.length > 11 ? 'CNPJ' : 'CPF' }} está inválido</small>
        </div>
        <div class="form-group">
          <input class="form-control" type="text" [mask]="mascaraCelular" placeholder="Celular"
            formControlName="Celular">
            <mat-icon class="icon material-icons-outlined">edit_note</mat-icon>
            <small *ngIf="celular.errors && celular.errors.required && (celular.dirty || celular.touched)">O celular é
              obrigatório</small>
        </div>
        <div class="form-group">
          <input class="form-control" type="email" placeholder="Email" formControlName="Email">
          <mat-icon class="icon material-icons-outlined">email</mat-icon>
          <small *ngIf="email.errors && email.errors.required && (email.dirty || email.touched)">O e-mail é
            obrigatório</small>
          <small *ngIf="email.errors && email.errors.email && (email.dirty || email.touched)">O e-mail está
            inválido</small>
        </div>
        <div class="form-group">
          <input class="form-control" name="password" type="password" id="password" (blur)="validaSenha()"
            placeholder="Senha" formControlName="Senha" [type]="mostrarSenha ? 'text' : 'password'">
          <mat-icon class="material-icons-outlined" style="font-size: 1.5rem;" (click)="alterarVisibildadeSenha()">{{ mostrarSenha ? 'visibility_off' : 'visibility'}}</mat-icon>
          <mat-icon class="icon material-icons-outlined">lock</mat-icon>
        </div>
        <div class="form-group">
          <input class="form-control" type="password" id="password2" placeholder="Confirmar senha"
            formControlName="confirmarSenha" (blur)="validaSenha()">
            <mat-icon class="icon material-icons-outlined">lock</mat-icon>
          <small *ngIf="senhaInvalida">{{ senhaInvalida }}</small>
        </div>

        <div *ngIf="erro" class="erro">{{ erro }}</div>

        <button [disabled]="!podeCadastrar" class="btn_1 full-width" (click)="cadastrar()">Cadastrar
          agora</button>
        <div class="text-center mt-2"><small>Já tem uma conta? <strong><a
                (click)="abrirTelaLogin()" class="entrar">Entrar</a></strong></small>
        </div>
      </form>
    </aside>
  </div>
</div>
