<!-- /col -->
<div [style.top.px] = "resumoPedidosPosicaoTop" class="box_order" [ngClass]="telaConclusaoPedido ? 'conclusao' : '' " #resumoPedidos>
  <div class="head" [ngClass]="telaConclusaoPedido ? 'margem-titulo' : ''">
    <h3 *ngIf="!indoor">Resumo do pedido</h3>
    <h3 *ngIf="indoor && numeroMesa">Receber na mesa {{ numeroMesa }}</h3>
  </div>

  <!-- /head -->
  <div class="main" style="color: black;">
    <div class="modal_body">
      <div *ngIf="pedidoMinimoErro && !indoor" style="margin-bottom: 30px"> O pedido mínimo para essa loja é de <span
          style="font-weight: bold;">{{ pedidoMinimoErro | currency:'BRL'}}</span>, não inclusa a taxa de entrega.
      </div>

      <ul *ngIf="telaConclusaoPedido && !indoor">
        <li *ngIf="dataEntrega" style="justify-content: space-between; display: flex;">{{ previsaoTexto }}
          <span style="text-align: right;"> {{ mostrarAmanha ? "Amanhã, " : "" }} {{ dataEntrega | date:'HH:mm' }} -
            {{ (dataEntrega.getTime() + 10 * 60000) | date:'HH:mm'}}</span>
        </li>
        <li *ngIf="distancia && !indoor && !retirarNoLocal">Distância <span>{{ distancia }}</span></li>
      </ul>
      <div *ngIf="!podeRealizarPedido && !indoor" class="entrega-indisponivel">
        <ul class="clearfix">
          <li>
            <a (click)="abrirModalSelecaoOpcaoEntrega()">Entrega não disponível para o endereço selecionado.
              <strong>Clique aqui para alterar o tipo de entrega.</strong></a>
          </li>
          <li>
            <button (click)="abrirModalSelecaoOpcaoEntrega()" class="btn_1 full-width mb_5">Alterar método de entrega</button>
          </li>
        </ul>
      </div>
      <hr *ngIf="(pedidoMinimoErro || telaConclusaoPedido || !podeRealizarPedido) && !indoor">
      <ul class="clearfix">
        <li *ngFor="let item of itensCarrinho">
          <div *ngIf="!item.variacao" class="item">
            <figure (click)="editarItem(item)" style="margin: 0; position: relative;">
              <img [src]="imagemURL(item) ? imagemURL(item) : 'https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/menu-thumb-placeholder.jpg'" data-src="img/menu-thumb-1.jpg" alt="" class="lazy imagem">
              <button mat-mini-fab class="botao-editar">
                <mat-icon class="material-icons-outlined">edit</mat-icon>
              </button>
            </figure>
            <div (click)="editarItem(item)" class="infos-itens">
              <span style="width: 100%;">{{ item.mercadoria.NomeCurto }}</span>
              <span class="preco">
                {{ (calculaDesconto(item.mercadoria.PrecoVenda, item.mercadoria.DescontoVenda) * item.quantidade | currency:'BRL')}}
              </span>
            </div>
            <div class="quantidade">
              <mat-icon *ngIf="item.quantidade == 1" class="icons-quantidade material-icons-outlined" (click)="removerItemCarrinho(item.codigoItem)">delete</mat-icon>
              <mat-icon *ngIf="item.quantidade != 1" class="icons-quantidade material-icons-outlined" (click)="diminuirQuantidadeItemCarrinho(item)">remove</mat-icon>
              <span>{{ item.quantidade }}</span>
              <mat-icon class="icons-quantidade material-icons-outlined" (click)="aumentarQuantidadeItemCarrinho(item)">add</mat-icon>
            </div>
          </div>

          <div *ngIf="item.variacao" class="item">
            <figure (click)="editarItem(item)" style="margin: 0; position: relative;">
              <img [src]="imagemURL(item) ? imagemURL(item) : 'https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/menu-thumb-placeholder.jpg'" data-src="img/menu-thumb-1.jpg" alt="" class="lazy imagem">
              <button mat-mini-fab class="botao-editar">
                <mat-icon class="material-icons-outlined">edit</mat-icon>
              </button>
            </figure>
            <div (click)="editarItem(item)" class="infos-itens">
              <span style="width: 100%;">
                {{ 
                  item.mercadoria.NomeCurto + " - " + 
                  item.variacao.NomeComponente1 + " " + 
                  item.variacao.NomeComponente2
                }}
              </span>
              <span class="preco">
                {{ 
                  (getPrecoVariacao(item.variacao.PrecoVenda, item.variacao.PrecoVendaComDesconto, item.quantidade) |
                  currency:'BRL' ) 
                }}
              </span>
            </div>
            <div class="quantidade">
              <mat-icon *ngIf="item.quantidade == 1" class="icons-quantidade material-icons-outlined" (click)="removerItemCarrinho(item.codigoItem)">delete</mat-icon>
              <mat-icon *ngIf="item.quantidade != 1" class="icons-quantidade material-icons-outlined" (click)="diminuirQuantidadeItemCarrinho(item)">remove</mat-icon>
              <span>{{ item.quantidade }}</span>
              <mat-icon class="icons-quantidade material-icons-outlined" (click)="aumentarQuantidadeItemCarrinho(item)">add</mat-icon>
            </div>

            <div *ngIf="item.variacao.DescricaoComponente1" style="font-size: 12px; margin-left: 4rem;">
              <div style="width: 75%; display: inline-block;">
                {{
                  item.variacao.DescricaoComponente1
                }}
              </div>
            </div>

            <div *ngIf="item.variacao.DescricaoComponente2" style="font-size: 12px; margin-left: 4rem;">
              <div style="width: 75%; display: inline-block;">
                {{
                  item.variacao.DescricaoComponente2
                }}
              </div>
            </div>

          </div>

          <div *ngIf="item.composicoes">
            <div *ngFor="let composicao of item.composicoes; let ic = index" style="font-size: 12px; margin-left: 4rem;">
              <div style="width: 75%; display: inline-block;">
                {{ 
                  composicao.TipoItemVenda != "Com variação" ?
                  (composicao.NomeCurto) + " - " + composicao.Quantidade + "x" : (composicao.NomeComVariacao || composicao.NomeCurto) + " " + 
                  composicao.CompVar1 + " - " + composicao.Quantidade + "x"
                }}
              </div>
              <div *ngFor="let preparo of preparosComposicao(composicao.CodBasicoContida, item.preparos, ic)" style="font-size: 12px; margin-left: 1rem;">
                <div style="width: 75%; display: inline-block;">{{preparo.Nome}}</div>
                <span>
                  {{ 
                    (preparo.PrecoVenda > 0 ? '+ ' + (preparo.PrecoVenda * preparo.Quantidade | currency:'BRL') : '')
                  }}
                </span>
              </div>
            </div>
          </div>
          
          <div *ngFor="let preparo of item.preparos" >
            <div *ngIf="preparo.CodComposicao == null" style="font-size: 12px; margin-left: 4rem;">
              <div style="width: 75%; display: inline-block;">{{preparo.Nome}}</div>
              <span>
                {{ 
                  (preparo.PrecoVenda > 0 ? '+ ' + (preparo.PrecoVenda * preparo.Quantidade | currency:'BRL') : '')
                }}
              </span>
            </div>
          </div>

          <div style="font-size: 12px; margin-left: 4rem;">{{ item.observacao }}</div>
        </li>
      </ul>
    </div>

    <div class="modal_footer">
      <ul class="clearfix" style="color: black;">
        <li>Subtotal<span>{{ (sacolaService.valorTotal | currency:'BRL') }}</span></li>
        <li *ngIf="podeRealizarPedido && !retirarNoLocal && taxaEntrega > 0 && !indoor">
          Taxa de entrega
          <span>
            {{ (taxaEntrega | currency:'BRL') }}
          </span>
        </li>

        <li *ngIf="podeRealizarPedido && !retirarNoLocal && taxaEntrega <= 0 && !indoor">
          Taxa de entrega
          <span style="color: green;">Grátis</span>
        </li>

        <li class="total">
          Total
          <span>
            {{ (valorTotalCarrinho | currency:'BRL') }}
          </span>
        </li>

      </ul>


      <div class="row opt_order" style="color: black;"
        *ngIf="!indoor && telaConclusaoPedido && (restaurante.HabilitarEntrega && restaurante.HabilitarPraRetirar)">
        <div class="col-6" *ngIf="restaurante.HabilitarEntrega">
          <label class="container_radio">Entregar
            <input type="radio" value="option1" name="opt_order" [checked]="!retirarNoLocal"
              (change)="alterarFormaRetirada(FormaRetirada.Entrega)">
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="col-6" *ngIf="restaurante.HabilitarPraRetirar">
          <label class="container_radio">Retirar
            <input type="radio" value="option1" name="opt_order" [checked]="retirarNoLocal"
              (change)="alterarFormaRetirada(FormaRetirada.Balcao)">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <form [formGroup]="form" *ngIf="telaConclusaoPedido && !indoor">
        <div style="margin: 10px 0px;" class="form-group">
          <label>CPF/CNPJ na nota</label>
          <input formControlName="CPFCNPJ" class="form-control" placeholder="CPF/CNPJ" [mask]="cpfCnpjMask"
            [validation]="false">
          <mat-slide-toggle formControlName="cpfCnpjNaNota"></mat-slide-toggle>
        </div>
      </form>

      <div *ngIf="!telaConclusaoPedido && restaurante.HabilitarAgendamento" class="dropdown day">
        <a href="#" data-toggle="dropdown">Dia<span id="selected_day"></span></a>
        <div class="dropdown-menu">
          <div class="dropdown-menu-content">
            <h4>Quando será entregue?</h4>
            <div class="radio_select chose_day">
              <ul>
                <li>
                  <input type="radio" id="day_1" name="day" value="Today">
                  <label for="day_1">Hoje<em>-40%</em></label>
                </li>
                <li>
                  <input type="radio" id="day_2" name="day" value="Tomorrow">
                  <label for="day_2">Amanhã<em>-40%</em></label>
                </li>
              </ul>
            </div>
            <!-- /people_select -->
          </div>
        </div>
      </div>
      <!-- /dropdown -->
      <div *ngIf="!telaConclusaoPedido && restaurante.HabilitarAgendamento" class="dropdown time">
        <a href="#" data-toggle="dropdown">Hora <span id="selected_time"></span></a>
        <div class="dropdown-menu">
          <div class="dropdown-menu-content">
            <h4>Almoço</h4>
            <div class="radio_select add_bottom_15">
              <ul>
                <li>
                  <input type="radio" id="time_1" name="time" value="12.00am">
                  <label for="time_1">12.00<em>-40%</em></label>
                </li>
                <li>
                  <input type="radio" id="time_2" name="time" value="08.30pm">
                  <label for="time_2">12.30<em>-40%</em></label>
                </li>
                <li>
                  <input type="radio" id="time_3" name="time" value="09.00pm">
                  <label for="time_3">1.00<em>-40%</em></label>
                </li>
                <li>
                  <input type="radio" id="time_4" name="time" value="09.30pm">
                  <label for="time_4">1.30<em>-40%</em></label>
                </li>
              </ul>
            </div>
            <!-- /time_select -->
            <h4>Janta</h4>
            <div class="radio_select">
              <ul>
                <li>
                  <input type="radio" id="time_5" name="time" value="08.00pm">
                  <label for="time_1">20.00<em>-40%</em></label>
                </li>
                <li>
                  <input type="radio" id="time_6" name="time" value="08.30pm">
                  <label for="time_2">20.30<em>-40%</em></label>
                </li>
                <li>
                  <input type="radio" id="time_7" name="time" value="09.00pm">
                  <label for="time_3">21.00<em>-40%</em></label>
                </li>
                <li>
                  <input type="radio" id="time_8" name="time" value="09.30pm">
                  <label for="time_4">21.30<em>-40%</em></label>
                </li>
              </ul>
            </div>
            <!-- /time_select -->
          </div>
        </div>
      </div>

      <!-- /dropdown -->
      <div class="btn_1_mobile">
        <button [disabled]="carregando || !podeRealizarPedido" (click)="pedirAgora()" class="btn_1 full-width mb_5">
          Pedir agora
        </button>
        <div *ngIf="!telaConclusaoPedido" class="text-center">
          <small>Nenhum valor cobrado nesta etapa</small>
        </div>
        <div class="text-center text-button" (click)="esvaziarCarrinho()">
          Esvaziar carrinho
        </div>
      </div>

      <div *ngIf="mensagemErro && telaConclusaoPedido" class="erro"> {{ mensagemErro }}</div>
    </div>
  </div>
</div>
