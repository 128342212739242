
<div class="pedido-info">
  <div class="titulo">{{ 'Pedido ' + pedido.Referencia }}</div>
  
  <div class="data-pedido">{{ pedido.Modulo }}</div>
  <div class="data-pedido">{{ getDiaSemana(pedido.Data) }} <span class="circulo"></span> {{ getDataFormatada(pedido.Data) }}</div>

  <div class="titulo">{{ pedido.Restaurante}}</div>
  <div>{{pedido.Total | currency:'BRL'}}</div>
  <div>{{pedido.Estado}}</div>
  <div *ngIf="pedido.Vendedor != 'Registro excluído'">{{pedido.Vendedor}}</div>
</div>
